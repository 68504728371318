.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black-filtered);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 60%;
  height: 80%;
}
