h2.modal-title {
  text-align: center;
}

h3.toc {
  margin: 1rem 1.5rem;
}

.toc {
  margin-left: 1.5rem;
  margin-bottom: 1.75em;
}

.toc li.toc-link {
  cursor: pointer;
}

.toc li.toc-link:hover {
  border: 1px solid var(--accent-bold);
  padding: calc(0.5rem - 1px) calc(1rem - 1px);
}

.toc li.toc-link:hover .selected {
  right: calc(-3.75rem - 1px);
}

.toc li {
  display: flex;
  padding: 0.5rem 1rem;
  margin: 1rem;
  position: relative;
}

.toc li .title {
  order: 1;
}

.toc li .chapter {
  font-size: smaller;
  margin-top: -0.1rem;
  order: 3;
}

.toc li .selected {
  position: absolute;
  right: -3.75rem;
  order: 4;
  font-weight: bold;
}

.toc li::after {
  background-image: radial-gradient(
    circle,
    currentcolor 1px,
    transparent 1.5px
  );
  background-position: bottom;
  background-size: 1ex 4.5px;
  background-repeat: space no-repeat;
  content: "";
  flex-grow: 1;
  height: 1em;
  order: 2;
}

.legend {
  display: flex;
  justify-content: space-around;
  margin: 1.75rem auto;
  width: 80%;
  padding: 1rem 0;
  border: 1px solid var(--gray);
}

.notice {
  width: 60%;
  margin: auto;
  margin-top: -1rem;
  text-align: center;
  font-style: italic;
  font-size: 0.75rem;
}
