footer {
  color: var(--white);
  background-color: var(--black);
  padding: 0.5rem;
  font-style: italic;
  display: flex;
  justify-content: right;
}

.logo {
  width: 100%;
  height: 100%;
  transform: translate(-3.5px);
}

.logo-container {
  max-height: 3.5rem;
  max-width: 14.5rem;
  background: white;
  padding: 0.75rem;
  border-radius: 9px;
}
