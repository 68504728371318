.button {
  min-width: 100px;
  min-height: 44px;
  background-color: var(--accent-bold);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 1rem 1.5rem;
  border-style: outset;
  font-family: var(--title-font);
  font-size: 0.75rem;
}

.button:hover {
  background-color: var(--accent-bold-light);
}

.button:active {
  background-color: var(--accent-bold-dark);
  border-style: inset;
}

.button-subdued {
  background-color: var(--accent-subdued);
  color: var(--black);
  border-color: var(--white);
  border-width: 1px;
}

.button-subdued:hover {
  background-color: var(--accent-subdued-light);
}

.button-subdued:active {
  background-color: var(--accent-subdued-dark);
  border-style: inset;
}

.button-small {
  min-height: 0;
  padding: 0.25rem 1.5rem;
}

.button-disabled {
  background-color: var(--accent-bold-disabled);
  border-style: outset;
  cursor: not-allowed;
}

.button-disabled:hover {
  background-color: var(--accent-bold-disabled);
  border-style: outset;
}

.button-disabled:active {
  background-color: var(--accent-bold-disabled);
  border-style: outset;
}
