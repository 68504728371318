.link {
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
  margin: 0.25rem 0rem;
}

.link:hover {
  color: var(--accent-bold);
}
