.code-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.code-text {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
