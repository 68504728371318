.tree-box {
  margin-bottom: 1rem;
  width: 100%;
  height: 100%;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  background-image: radial-gradient(var(--gray) 1px, transparent 0);
  background-size: 30px 30px;
  font-weight: lighter;
}

.manipulations {
  display: flex;
  justify-content: right;
}

.node_root > circle {
  fill: var(--accent-subdued);
  stroke-width: 1;
}

.node_branch > circle {
  fill: var(--accent-subdued-light);
  stroke-width: 1;
}

.node_leaf > circle {
  fill: var(--white);
}
