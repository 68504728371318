section > p {
  text-align: justify;
}

.react-tabs__tab-panel {
  padding: 1rem 0.5rem;
}

.react-tabs__tab-list {
  margin-bottom: 0px;
}

.react-tabs__tab:focus {
  outline: inherit;
}

.instr-img {
  width: 200px;
  height: 100px;
  margin-bottom: 0.5rem;
  margin-top: 0.75rem;
}

.left {
  float: left;
  margin-right: 1rem;
}

.right {
  float: right;
  margin-left: 1rem;
}

.display {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.reserved-strings {
  position: relative;
  border: 1px solid var(--gray);
  padding: 1.25rem;
  width: 80%;
  margin: auto;
}

.reserved-strings .caption {
  position: absolute;
  right: 0.5rem;
  bottom: -0.4rem;
  font-size: smaller;
  background: white;
  padding: 0 0.5rem;
}

.railroad-diagram {
  text-align: center;
}

.railroad-diagram .caption {
  margin: 0.5rem;
}

.railroad-diagram img {
  margin-bottom: 2rem;
}
