:root {
  --white: #f6f8f6;
  --black: #241b32;
  --gray: #827f86;
  --black-filtered: #241b32a5;

  --main: #aaddca;
  --main-dark: #378b6c;

  --accent-bold: #a35369;
  --accent-bold-light: #924a5e;
  --accent-bold-dark: #824254;
  --accent-bold-disabled: #a88791;

  --accent-subdued: #96d0e9;
  --accent-subdued-light: #cfe9f5;
  --accent-subdued-dark: #56b3db;

  --toastify-color-success: var(--main-dark);
  --toastify-color-error: var(--accent-bold);
  --toastify-color-info: var(--accent-subdued);

  --title-font: "bebas_kai";

  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
  width: 100vw;
  border: none;
  padding: 0;
  margin: 0;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
  font-style: italic;
}

section > p {
  margin: 1rem 0rem;
}

ul {
  margin: 1rem 3rem;
}

li {
  padding-left: 0.25rem;
}

table {
  width: 80%;
  border: 1px solid var(--gray);
  margin: 1.5rem auto;
}

textarea {
  flex-grow: 1;
  resize: none;
  margin-bottom: 1rem;
}

input {
  flex-grow: 1;
  margin-right: 1rem;
}
