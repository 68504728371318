main {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.column {
  width: 50%;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
}
